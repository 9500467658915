import { VICTIMS } from '../../constants/victim'
import { Victim } from '../../types/victimTypes'

export class VictimService {
  private victims: Victim[] = VICTIMS
  public victimOne: Victim
  public victimTwo: Victim
  public doomedVictim: Victim | undefined
  public victimsEncounteredThisSession: Victim[] = []

  constructor() {
    this.victimOne = this.getNewVictim()
    this.victimTwo = this.getNewVictim()
    this.doomedVictim = undefined
    //void this.getVictimsFromDB()
  }

  public getNewVictim(): Victim {
    const victim = this.victims[Math.floor(Math.random() * this.victims.length)]
    if (
      victim === this.victimOne ||
      victim === this.victimTwo ||
      this.victimsEncounteredThisSession.includes(victim)
    ) {
      return this.getNewVictim()
    }
    this.incrementVictimAppearances(victim.name)
    this.victimsEncounteredThisSession.push(victim)
    return victim
  }

  public replaceVictims(): void {
    this.victimOne = this.getNewVictim()
    this.victimTwo = this.getNewVictim()
  }

  public doomVictim(victimName: string): void {
    if (victimName === this.victimOne.name) {
      this.doomedVictim = this.victimOne
    } else {
      this.doomedVictim = this.victimTwo
    }
    this.incrementVictimDeathSentences()
  }

  public getPercentKilled() {
    const numAppearances = this.doomedVictim?.appearances ?? 0
    const numDeathSentences = this.doomedVictim?.deathSentences ?? 0

    return String(Math.round((numDeathSentences / numAppearances) * 100))
  }

  public getNumTimesKilled() {
    return String(this.doomedVictim?.deathSentences ?? 0)
  }

  public incrementVictimAppearances(victimName: string) {
    const victimToIncrement = this.victims.find(
      (victim) => victim.name === victimName,
    )
    victimToIncrement!.appearances++
  }

  public incrementVictimDeathSentences() {
    this.victims.find((victim) => victim.name === this.doomedVictim?.name)!
      .deathSentences++
  }

  public async getVictimsFromDB(): Promise<void> {
    console.log('🐄 getVictimsFromDB')
    try {
      //const res = await pool.query('SELECT * FROM victims')
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      // const victimsData: Victim[] = res.rows.map((row: any) => ({
      //   name: row.name as string,
      //   appearances: row.appearances as number,
      //   deathSentences: row.deathsentences as number,
      //   image: row.image as string,
      // }))
      // this.victims = victimsData
      // console.log('🐄 this.victims', this.victims)
    } catch (error) {
      console.error(`Error loading victims from DB: ${error}`)
    }
  }

  public async updateVictimInDB(): Promise<void> {
    try {
      // const query = `
      //   INSERT INTO victims (name, appearances, deathSentences, image)
      //   VALUES ($1, $2, $3, $4)
      //   ON CONFLICT (name)
      //   DO UPDATE SET appearances = EXCLUDED.appearances, deathSentences = EXCLUDED.deathSentences
      // `
      // const values = [
      //   victim.name,
      //   victim.appearances,
      //   victim.deathSentences,
      //   victim.image,
      // ]
      // await pool.query(query, values)
    } catch (error) {
      console.error(`Error updating victim in DB: ${error}`)
    }
  }

  public async updateVictimsEncountered(): Promise<void> {
    // try {
    //   for (const victim of this.victimsEncounteredThisSession) {
    //     //await this.updateVictimInDB(victim)
    //   }
    // } catch (error) {
    //   console.error(`Error updating victims encountered this session: ${error}`)
    // }
  }
}
