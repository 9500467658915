import React, { useEffect, useMemo, useState } from 'react'
import { SplashScreen } from './screens/SplashScreen/SplashScreen'
import './App.css'
import { AppExecutor } from './executors/AppExecutor'
import { useSceneStore } from './state/gameState'
import { Scenes } from './constants/game'
import { IntroScreen } from './screens/IntroScreen/IntroScreen'
import { WhoLivesQuestionScreen } from './screens/WhoLivesQuestionScreen/WhoLivesQuestionScreen'
import { SentenceVictimScreen } from './screens/SentenceVictimScreen/SentenceVictimScreen'
import { DecisionStatsScreen } from './screens/DecisionStatsScreen/DecisionStatsScreen'
import { Analytics } from '@vercel/analytics/react'

const App = () => {
  const currentScene = useSceneStore(
    (state: { currentScene: Scenes }) => state.currentScene,
  )
  const setScene = useSceneStore(
    (state: { setScene: (arg: Scenes) => void }) => state.setScene,
  )
  const executor = useMemo(() => new AppExecutor(setScene), [setScene])

  const [victims, setVictims] = useState([])

  useEffect(() => {
    executor.exec()
  })

  useEffect(() => {
    const fetchVictims = async () => {
      try {
        const response = await fetch('/api/database')
        if (response.ok) {
          const data = await response.json()
          setVictims(data)
          console.log('victims received', JSON.stringify(victims))
        } else {
          console.error('Failed to fetch victims')
        }
      } catch (error) {
        console.error('Error:', error)
      }
    }

    fetchVictims()
  }, [])

  return (
    <div className="appContainer">
      {currentScene === Scenes.Splash && (
        <SplashScreen
          setScene={setScene}
          {...executor.getSplashScreenProps()}
        />
      )}
      {currentScene === Scenes.Intro && (
        <IntroScreen setScene={setScene} {...executor.getIntroScreenProps()} />
      )}

      {currentScene === Scenes.WhoLivesQuestion && (
        <WhoLivesQuestionScreen
          setScene={setScene}
          {...executor.getWhoLivesQuestionScreenProps()}
        />
      )}

      {currentScene === Scenes.SetenceVictim && (
        <SentenceVictimScreen
          setScene={setScene}
          {...executor.getSentenceVictimScreenProps()}
        />
      )}

      {currentScene === Scenes.DecisionStats && (
        <DecisionStatsScreen
          setScene={setScene}
          {...executor.getDecisionStatsScreenProps()}
        />
      )}
      <Analytics />
    </div>
  )
}

export default App
