import { IMAGES } from '../../constants/image'
import './IntroScreen.css'
import { DefaultScreenProps } from '../defaultScreenProps'
import { useRef, useEffect } from 'react'

type IntroScreenProps = DefaultScreenProps & {
  sayIntroCopy: () => void
}

export const IntroScreen = ({ sayIntroCopy }: IntroScreenProps) => {
  const audioPlayedRef = useRef(false)

  useEffect(() => {
    if (!audioPlayedRef.current) {
      sayIntroCopy()
      audioPlayedRef.current = true
    }
  }, [sayIntroCopy])

  return (
    <div className="introScreenContainer">
      <img
        className="introScreenImage"
        src={IMAGES.intro}
        onClick={sayIntroCopy}
        alt="angry looking trolley"
      />
    </div>
  )
}
