/* eslint-disable react/react-in-jsx-scope */
import './WhoLivesQuestionScreen.css'
import { DefaultScreenProps } from '../defaultScreenProps'
import { useEffect, useRef } from 'react'

type WhoLivesQuestionScreenProps = DefaultScreenProps & {
  sayVictimIntro: () => void
  promptWhoLives: () => void
  getVictimImages: () => { leftVictim: string; rightVictim: string }
  handleLeftClick: () => void
  handleRightClick: () => void
}

export const WhoLivesQuestionScreen = ({
  sayVictimIntro,
  promptWhoLives,
  getVictimImages,
  handleLeftClick,
  handleRightClick,
}: WhoLivesQuestionScreenProps) => {
  const audioPlayedRef = useRef(false)

  useEffect(() => {
    if (!audioPlayedRef.current) {
      sayVictimIntro()
      promptWhoLives()
      audioPlayedRef.current = true
    }
  }, [sayVictimIntro, promptWhoLives])

  const { leftVictim, rightVictim } = getVictimImages()
  return (
    <div className="whoLivesQuestionScreenContainer">
      {audioPlayedRef && (
        <div className="clickableAreas">
          <img
            className="leftVictimImage"
            src={leftVictim}
            alt="left victim"
            onClick={() => handleLeftClick()}
          />
          <img
            className="rightVictimImage"
            src={rightVictim}
            alt="right victim"
            onClick={() => handleRightClick()}
          />
        </div>
      )}
    </div>
  )
}
